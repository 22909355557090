import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => {
  const [display, setDisplay] = React.useState(false)
  const toogleClass = () => {
    setDisplay(!display);
  }
  return (
    <Layout>
      <Seo title="Progressive Web Apps - Jamstack website - Headless CMS" />
      <h1 onClick={toogleClass} aria-hidden="true">PWA Demo</h1>
      <div id="summary" className={display ? "e-show" : "e-hide"}>
        <p>VNSP Jamstack website, headless CMS, build the best business solutions on
          NodeJS, React, NextJS, MongoDB, GraphQL...Design the fastest and optimized websites base on cloud platform.</p>
        <p>Gatsby JS sites are fully functional React apps, create high-quality,
        dynamic website, serverless rendering generates static HTML,
        minimal bootstrap React app with router code & data splitting.</p>
        <p>Build, preview, deploy, and scale your next website on <a href="https://gitlab.com/vnsp">GitLab</a> repositories.</p>
        <p>Headless CMS makes content accessible via an API for display on any device, without a built-in front-end or presentation layer.</p>
        <p>
          <h3>CI/CD - building, testing and deployment</h3>
          <ul>
            <li>CI/CD is the combined practices of continuous integration (CI) and continuous deployment (CD),
                CI/CD bridges the gaps between development and operation activities and teams by enforcing automation in building, testing and deployment of applications: <Link to="/github-cicd">CI/CD Demo</Link>.
            </li>
            <li>Using Docker Hub to create, manage, and deliver container applications: <Link to="/docker">Pull from Docker Hub</Link>.
              </li>
            <li><Link to="/using-typescript/">Using TypeScript</Link>.</li>
          </ul>
        </p>
      </div>
      <p>
        <strong>Docker Command:</strong><br />
        <code>docker pull vnsp/gatsbyjs</code><br />
        GitLab pull from @gatsbyjs
      </p>
    </Layout>
  )
}
export default IndexPage
